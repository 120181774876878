document.addEventListener("DOMContentLoaded", () => {
    // Копирование в буфер обмена
    document.querySelectorAll(".admin__buffer").forEach(btn => {
        btn.addEventListener("click", e => {
            e.preventDefault();

            navigator.clipboard.writeText(e.target.innerText).then(() => {
                btn.classList.add("admin__buffer_success");
            }, (err) => {
                btn.classList.add("admin__buffer_error");
                console.error("Не удалось скопировать: ", err, btn);
            });

            setTimeout(() => {
                btn.classList.remove("admin__buffer_success", "admin__buffer_error");
            }, 5000);
        });
    });
});